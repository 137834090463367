import React, { useState } from "react";
import usePagination from "../../_api/usePagination";
import Gallery from "react-grid-gallery";
import { css } from "@emotion/react";
import Layout from "../../components/layout";
import {
  FaArrowCircleLeft,
  FaDownload,
  FaShare,
  FaShareAlt,
  FaSpinner,
} from "react-icons/fa";
import { useQuery } from "react-query";
import { Link } from "gatsby";
export default function Album({ params }) {
  const AlbumDetails = useQuery(["album-details", params.id], async () => {
    return fetch(`/api/get-album-details?id=${params.id}`).then((res) =>
      res.json()
    );
  });

  const {
    album,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    isFetching,
    fetchNextPage,
    data,
    ...query
  } = usePagination({
    key: "album",
    param: params.id,
    endpoint: "get-album",
  });

  const images = album
    ?.map((photo) => {
      const thumb = photo?.images[photo?.images?.length - 1];
      return photo?.images[0]
        ? {
            id: photo.id,
            src: photo?.images[2].source,
            thumbnail: thumb.source,
            thumbnailWidth: thumb.width,
            thumbnailHeight: thumb.height,
            caption: photo?.album?.name,
          }
        : null;
    })
    .filter(Boolean);
  const [currentImage, setCurrentImage] = useState(0);
  return (
    <Layout>
      <div
        className="container py-12 lg:py-20"
        css={css`
          /* .ReactGridGallery_tile-viewport {
            display: grid;
            place-items: center;
          } */

          img {
            margin: 0 !important;
            object-fit: cover !important;
          }
        `}
      >
        {" "}
        <Link to="/" className="inline-flex items-center mb-8 space-x-2">
          <FaArrowCircleLeft />
          <span>Zurück </span>
        </Link>
        <div className="mb-8 prose dark:prose-dark max-w-none lg:mb-12">
          <h1>{AlbumDetails?.data?.name}</h1>
        </div>
        {isLoading && (
          <div className="flex items-center justify-center py-20">
            <FaSpinner className="w-10 h-10 animate-spin" />
          </div>
        )}
        {!isLoading && (
          <Gallery
            backdropClosesModal
            enableImageSelection={false}
            currentImageWillChange={(i) => setCurrentImage(i)}
            onClickImage={(e) => {
              // null
            }}
            customControls={[
              <a
                href={`https://www.facebook.com/share.php?u=https://www.facebook.com/top10balingen/photos/${images[currentImage].id}`}
                target="_blank"
                className="inline-flex items-center justify-center font-semibold text-white"
              >
                Share <FaShare className="inline-block ml-2" />
              </a>,
              <a
                download
                href={`${images[currentImage].src.replace(
                  "https://z-p3-scontent.fzag1-2.fna.fbcdn.net/",
                  "/fb-assets/"
                )}`}
                className="absolute bottom-[60px] right-[20px] inline-flex w-10 h-10 items-center justify-center font-semibold text-black bg-white/30 hover:bg-white/50"
              >
                <FaDownload className="inline-block w-" />
              </a>,
            ]}
            images={images}
          />
        )}
        {hasNextPage && (
          <div
            onClick={() => {
              fetchNextPage();
            }}
            className="h-[180px] mt-0.5 ml-0.5 cursor-pointer float-left w-48 bg-blue-light flex items-center justify-center"
          >
            Load More{" "}
            {isFetching && <FaSpinner className="ml-3 animate-spin" />}
          </div>
        )}
        <div className="clear-both"></div>
        {/* <pre>{JSON.stringify(album, null, 2)}</pre> */}
      </div>
    </Layout>
  );
}
